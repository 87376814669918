import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "./main-page.css";

function MainPage() {
  const swiperContainer = React.useRef();
  const swiperNextText = React.useRef();

  const changeBackground = (index) => {
    swiperContainer.current.classList.remove("zoom-one");
    swiperContainer.current.classList.remove("zoom-two");
    swiperContainer.current.classList.remove("zoom-three");
    swiperContainer.current.classList.remove("zoom-four");
    swiperContainer.current.classList.remove("zoom-five");
    swiperContainer.current.classList.remove("zoom-six");

    if (index === 0) {
      swiperContainer.current.classList.add("zoom-one");
    } else if (index === 1) {
      swiperContainer.current.classList.add("zoom-two");
    } else if (index === 2) {
      swiperContainer.current.classList.add("zoom-three");
    } else if (index === 3) {
      swiperContainer.current.classList.add("zoom-four");
    } else if (index === 4) {
      swiperContainer.current.classList.add("zoom-five");
    } else if (index === 5) {
      swiperContainer.current.classList.add("zoom-six");
    }
  };

  const appearanceSlideContent = (index) => {
    const contentArray = document.querySelectorAll(".main-page__content");
    contentArray.forEach((content) => {
      content.classList.remove("main-page__content--emergence");
    });

    contentArray[index].classList.add("main-page__content--emergence");
  };

  return (
    <div className="main-page">
      <Swiper
        ref={swiperContainer}
        effect={"fade"}
        loop={true}
        speed={1200}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          nextEl: ".main-page__arrow-next",
        }}
        pagination={{
          el: ".main-page__pagination",
          clickable: true,
          renderBullet: (index, className) => {
            let categoryName;

            function categorySelection(number) {
              if (number === 0) {
                return (categoryName = "Мансардные окна");
              } else if (number === 1) {
                return (categoryName = "Керамическая черепица");
              } else if (number === 2) {
                return (categoryName = "Кровельные материалы");
              } else if (number === 3) {
                return (categoryName = "Кровельный алюминий и медь");
              } else if (number === 4) {
                return (categoryName = "Водосточные системы");
              } else if (number === 5) {
                return (categoryName = "Металлическая черепица");
              }
            }

            return `<div class="main-page__pagination-item ${className}">
                  <span class="main-page__pagination-item-text">${categorySelection(
                    index
                  )}</span>
                  <span class="main-page__pagination-item-toggle"></span>
                </div>`;
          },
        }}

        onSlideChange={(swiper) => {
          
          changeBackground(swiper.activeIndex);
          appearanceSlideContent(swiper.activeIndex);
          
          if (swiper.activeIndex === 7 || swiper.activeIndex === 1) {
            swiperNextText.current.textContent = "Керамическая черепица";
          } else if (swiper.activeIndex === 2) {
            swiperNextText.current.textContent = "Кровельные материалы";
          } else if (swiper.activeIndex === 3) {
            swiperNextText.current.textContent = "Кровельный алюминий и медь";
          } else if (swiper.activeIndex === 4) {
            swiperNextText.current.textContent = "Водосточные системы";
          } else if (swiper.activeIndex === 5) {
            swiperNextText.current.textContent = "Металлическая черепица";
          } else if (swiper.activeIndex === 6) {
            swiperNextText.current.textContent = "Мансардные окна";
          }

        }}

        onSwiper={(swiper) => {
          changeBackground(swiper.activeIndex);
        }}
      >
        <SwiperSlide className="swiper-slide swiper-slide--first">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Мансардные окна в Калининграде
                <br />
                От лидера отрасли Dakea
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-windows">
                  Каталог
                </Link>
                <a
                  href="https://www.youtube.com/watch?v=tiHkOM2IdzE"
                  title="Производство Dakea"
                  className="main-page__button main-page__button--video"
                  rel="noreferrer"
                  target="_blank"
                >
                  Производство
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide swiper-slide--second">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Керамическая черепица Wienerberger
                <br />
                Сочетание цены и качества
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-ceramic">
                  Каталог
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide swiper-slide--third">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Материалы для кровли Corotop
                <br />
                Продукция проверенная временем
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-accessories">
                  Каталог
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide swiper-slide--fourth">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Кровельный и фасадный металл
                <br />
                Алюминий и медь от Sevalcon
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-metals">
                  Подробнее
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide swiper-slide--five">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Водосточные системы
                <br />
                От Европейского производителя
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-drainage-systems">
                  Каталог
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide swiper-slide--six">
          <div className="container">
            <div className="main-page__content">
              <h2 className="main-page__title">
                Металлическая черепица
                <br />
                Европейское качество
              </h2>
              <div className="main-page__buttons-container">
                <Link className="main-page__button" to="/catalog-metal-tiles">
                  Каталог
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div className="container">
          <div className="main-page__arrow-container">
            <div className="container">
              <div className="main-page__arrow-wrapper">

                <div className="main-page__arrow-next-container">
                  <div className="main-page__arrow main-page__arrow-next">
                    <div className="main-page__arrow main-page__arrow-next--icon"></div>
                    <p ref={swiperNextText} className="main-page__arrow-next-text">Керамическая черепица</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="main-page__pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default MainPage;
