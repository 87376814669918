import * as contentful from 'contentful';

// Небезопасно, надо исправить

// Обращаемся к клиенту 
const client = contentful.createClient({
    space: "qyayc6t6bt9t",
    accessToken: "qr__4BOWTvteFOGWWhSwMlg3EmVCMEx6-k22xdmv8jo"
})

export default client;