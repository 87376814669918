import urlTransliterate from "./UrlTransliterate";
import { useLocation } from "react-router-dom";

  let endOfCurrentUrl;

  const selectionCurrentItem = (arrayElements) => {
    GetEndUrl();
    let returnableElement;
    arrayElements.forEach((element) => {
      if (urlTransliterate(element.fields.name) === endOfCurrentUrl) {
        returnableElement = element;
      }
    });

    if (returnableElement) {
      return returnableElement;
    }
  };

  function GetEndUrl() {
    endOfCurrentUrl = useLocation().pathname.split("/")[useLocation().pathname.split("/").length - 1];
  }

export default selectionCurrentItem;