import React from "react";
import classnames from "classnames";
import "./contacts-popap.css";
import { useHistory } from 'react-router-dom';

function ContactsPopap(props) {
  const [visiblePopap, setVisiblePopap] = React.useState(false);
  const history = useHistory();

  const changeVisiblePopap = (currentVisiblePopap) => {
    setVisiblePopap(!visiblePopap);
  };

  const formBlock = React.useRef();
  const formResultBlock = React.useRef();
  const formLoadingBlock = React.useRef();

  const openPopapButton = React.useRef();
  const closePopapButton = React.useRef();
  const outlinePopapButton = React.useRef();

  // функция отправки формы на почту
  async function formSend(e) {
    e.preventDefault();
    let form = formBlock.current;
    let formResult = formResultBlock.current;
    let formLoading = formLoadingBlock.current;

    // Действие во время отправки
    formLoading.classList.add("open");
    let formData = new FormData(form);
    form.reset();

    let response = await fetch("mail.php", {
      method: "POST",
      body: formData,
    });

    // Если сообщение отправлено
    if (response.ok) {
      let result = await response.json();
      form.reset();
      formLoading.classList.remove("open");
      formResult.classList.add("open");
      formResult.textContent = result.message;
      setTimeout(() => formResult.classList.remove("open"), 3000);
      setTimeout(() => history.push('/thanks'), 2000);
    } else {
      formResult.classList.add("open");
      formResult.textContent = "Ошибка, свяжитесь с нами по номеру телефона!";
      setTimeout(() => formResult.classList.remove("open"), 3000);
      formLoading.classList.remove("open");
      setTimeout(() => history.push('/error'), 2000);
    }
  }

  return (
    <div>
      <button
        className="contacts__open-popap-button"
        ref={openPopapButton}
        onClick={changeVisiblePopap}
      >
        {props.buttonName}
      </button>

      <div
        className={classnames("contacts__popap", {
          "contacts__popap--active": visiblePopap,
        })}
      >
        <div
          className="contacts__popap-outline"
          ref={outlinePopapButton}
          onClick={changeVisiblePopap}
        ></div>

        <form
          ref={formBlock}
          onSubmit={formSend}
          action="#"
          className="contacts__popap-form"
        >
          <span
            ref={closePopapButton}
            onClick={changeVisiblePopap}
            className="contacts__popap-button-close"
          ></span>
          <h2 className="contacts__popap-title">Оставить заявку</h2>
          <p className="contacts__popap-text-preview">{props.textPreview}</p>

          {/* Имя и email */}
          <div className="contacts__popap-block">
            <input
              type="text"
              className="visually-hidden"
              name="product"
              defaultValue={`${props.product} ${props.article && props.article} (${props.range})`}
            ></input>
            <input
              type="text"
              className="contacts__popap-input"
              placeholder="*Имя"
              name="name"
              autoComplete="off"
              min="2"
              maxLength="100"
              required
            ></input>
            <input
              type="text"
              className="contacts__popap-input"
              placeholder="*Телефон"
              name="phone"
              autoComplete="off"
              min="2"
              maxLength="20"
              required
            ></input>
            <input
              type="email"
              className="contacts__popap-input"
              placeholder="E-mail"
              name="email"
              autoComplete="off"
              min="2"
              maxLength="100"
            ></input>
          </div>

          <div className="contacts__popap-product-container">
            <h3 className="contacts__popap-product-title">Товар в заявке:
              <span> </span>
              <span className="contacts__popap-product-text">
                <span>{props.product} {props.article && props.article}</span>
                {props.range &&
                  <>
                    {props.range.includes("#") ? (
                      <div className="contacts__popap-range-item-container">
                        <span> - цвет </span>
                        <span
                          className="contacts__popap-range-item--color"
                          style={{
                            backgroundColor: `${props.range}`
                          }}
                        ></span>
                      </div>
                    ) : (
                      <span> ({props.range})</span>
                    )}
                  </>
                }
              </span>
            </h3>
          </div>

          <div className="contacts__popap-button-container">
            <button type="submit" className="contacts__popap-button">
              Отправить
            </button>
          </div>

          <div ref={formResultBlock} className="contacts__popap-result">
            <p className="contacts__popap-result-text"></p>
          </div>
          <div ref={formLoadingBlock} className="contacts__popap-loading">
            <p className="contacts__popap-loading-text">Отправка...</p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactsPopap;
