import React from "react";
import "./catalog-metals.css";
import { Link } from "react-router-dom";
import ContactsPopap from "../include/ContactsPopap";
import { Swiper, SwiperSlide } from "swiper/react";
import MetaTags from "react-meta-tags";
import SevalconLink from "../docs/Sevalcon.pdf";


import houseFirst from "../img/catalog-metals/house-first.jpeg";
import houseSecond from "../img/catalog-metals/house-second.jpeg";
import houseThird from "../img/catalog-metals/house-third.jpeg";
import houseFourth from "../img/catalog-metals/house-fourth.jpeg";

function CatalogMetals() {
  return (
    <div className="catalog-metals">
      <MetaTags>
        <title>Кровельный алюминий и медь</title>
        <meta
          name="description"
          content="Кровельный алюминий и медь в Калининграде. Большой каталог товаров, Низкие цены, Консультации опытных специалистов"
        />
        <meta
          name="keywords"
          content="Кровельный алюминий и медь, Мансардные окна в Калининграде, Недорогие мансардные окна, Большие мансардные окна, Окна в крышу, Окна на крыше, Окна Dakea, Окна в частный дом, ФБХ Калининград, ФБХ кровля"
        />
      </MetaTags>

      <div className="container">
        <div className="bread-crumbs">
          <ul className="bread-crumbs__list">
            <li className="bread-crumbs__item">
              <Link className="bread-crumbs__link" to="/">
                Главная
              </Link>
            </li>
            <li className="bread-crumbs__item">
              <span className="bread-crumbs__link bread-crumbs__link--active">
                Кровельный алюминий и медь
              </span>
            </li>
          </ul>
        </div>

        <div className="catalog-metals__wrapper">
          <div className="catalog-roof-windows__title-container">
            <h1 className="catalog-roof-windows__title">
              Кровельный алюминий и медь
            </h1>
            <p className="catalog-roof-windows__subtitle">
              Возможен любой вид рисунка
            </p>
          </div>
          <div className="catalog-metals__content">
            <p className="catalog-metals__text">
              Кровельный алюминий SEVALCON помимо широкой гаммы цветовых решений
              - от классического цвета меди, до цвета стали кортен, обладает и
              такими преимуществами как: экологичность, коррозийная стойкость,
              стойкость цвета к УФ-излучению, малый вес, долговечность.
            </p>
            <p className="catalog-metals__text">
              Алюминиевая кровля красивая, универсальная, долговечная, легкая,
              прочная, экономичная, экологичная, лекго монтируемая, максимально
              герметичная и ветроустойчивая. При таком количестве прилагательных
              она легко победит в любом соревновании среди кровельных
              материалов.
            </p>
            <p className="catalog-metals__text">
              У нашей компании "ФБХ Калининград" есть прямой договор с заводом
              изготовителем кровельного алюминия, благодаря которому мы можем
              предоставить уникальные розничные цены на продукцию.
            </p>
          </div>

          <div className="catalog-metals__buttons-container">
            <ContactsPopap
              buttonName="Связаться с нами"
              textPreview="Мы свяжемся с вами в течении 3 минут"
              product="Кровельный алюминий и медь"
            ></ContactsPopap>

            <a className="catalog-metals__button" href={SevalconLink}>
              Подробная презентация
            </a>
          </div>
        </div>
      </div>

      <Swiper
            loop={true}
            speed={400}
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              prevEl: ".gallery__arrow-prev",
              nextEl: ".gallery__arrow-next",
            }}
            pagination={{
              el: ".card-product__pagination",
              clickable: true,
              renderBullet: (index, className) => {
                return `<div class="${className}">
                </div>`;
              },
            }}
          >
            <SwiperSlide>
              <img className="gallery__slide-img" src={houseFirst}></img>
              {/* <div className="gallery__slide-description">
                <h3 className="gallery__slide-title">Мансардное окно Dakea</h3>
                <p className="gallery__slide-text">
                  Зеленоградск, Прибрежная 94
                </p>
              </div> */}
            </SwiperSlide>

            <SwiperSlide>
              <img className="gallery__slide-img" src={houseSecond}></img>
            </SwiperSlide>

            <SwiperSlide>
              <img className="gallery__slide-img" src={houseThird}></img>
            </SwiperSlide>

            <SwiperSlide>
              <img className="gallery__slide-img" src={houseFourth}></img>
            </SwiperSlide>

            <div className="container">
              <div className="main-page__arrow-container">
                <div className="container">
                  <div className="gallery__arrow-wrapper">
                    <div className="gallery__arrow gallery__arrow-prev"></div>
                    <div className="gallery__arrow gallery__arrow-next"></div>
                  </div>
                </div>
              </div>

              <div className="main-page__pagination"></div>
            </div>
          </Swiper>
    </div>
  );
}

export default CatalogMetals;
