import React from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

function Error() {
  return (
    <div className="thanks">

      <MetaTags>
        <title>Заявка не отправлена</title>
      </MetaTags>

      <div className="container">
        <div className="bread-crumbs">
          <ul className="bread-crumbs__list">
            <li className="bread-crumbs__item">
              <Link className="bread-crumbs__link" to="/">
                Главная
              </Link>
            </li>
            <li className="bread-crumbs__item">
              <span className="bread-crumbs__link bread-crumbs__link--active">
                Ошибка
              </span>
            </li>
          </ul>
        </div>
        <div className="text">
          <h1>Произошла ошибка!</h1>
          <h3>К сожалению ваша заявка не отправлена, пожалуйста свяжитесь с нами любым другим способом.</h3>
        </div>
      </div>
    </div>
  );
}

export default Error;
