import React from "react";
import "./about.css";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

function About() {
  return (
    <div className="about">

      <MetaTags>
        <title>О компании</title>
        <meta
          name="description"
          content="Компания ФБХ Калининград осуществляет поставки напрямую от заводов производителей, большой выбор, оптимальные цены. Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь."
        />
        <meta
          name="keywords"
          content="О компании, Все для кровли в Калининграде, Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь, Консультирование по кровельным материалам, Материалы для крыши, Крыша на доме, Качественная крыша, Надежная крыша, Недорогие кровельные материалы, ФБХ кровля, ФБХ Калининград"
        />
      </MetaTags>

      <div className="container">
        <div className="bread-crumbs">
          <ul className="bread-crumbs__list">
            <li className="bread-crumbs__item">
              <Link className="bread-crumbs__link" to="/">
                Главная
              </Link>
            </li>
            <li className="bread-crumbs__item">
              <span className="bread-crumbs__link bread-crumbs__link--active">
                О компании
              </span>
            </li>
          </ul>
        </div>

        <div className="about-wrapper">
          <div className="about__content">
            <h1 className="about__title">О нас</h1>
            <p className="about__text">
              Наша компания занимается поставками кровельных материалов в
              Калининграде с 1997 года. Более 3000 наших клиентов - владельцы
              частных домов, строительные компании и иные клиенты.
            </p>

            <p className="about__text">
              Большинство изделий всегда находится в наличии на нашем складе в
              городе Калининграде, а наши цены очень выгодные за счет прямых
              поставок от заводов производителей из и отсутствия посредников в
              торговой цепочке.
            </p>

            <p className="about__text">
              Обращаясь к нам — вы получите реальные советы, которые подскажут
              как сделать вашу мансарду уютным и светлым помещением, в котором
              приятно жить и работать.
            </p>

            <ul className="about__desc-list">
              <li className="about__desc-item">
                <span className="about__desc-number">
                  3 <span className="about__desc-unit">тысячи</span>
                </span>
                <span className="about__desc-item-text">
                  клиентов
                  <br /> обслужено
                </span>
              </li>
              <li className="about__desc-item">
                <span className="about__desc-number">
                  24 <span className="about__desc-unit">года</span>
                </span>
                <span className="about__desc-item-text">
                  опыт работы
                  <br /> компании
                </span>
              </li>
              <li className="about__desc-item">
                <span className="about__desc-number">
                  500 <span className="about__desc-unit">позиций</span>
                </span>
                <span className="about__desc-item-text">
                  товаров
                  <br /> в наличии
                </span>
              </li>
            </ul>
          </div>

          <div className="about__image"></div>
        </div>
      </div>
    </div>
  );
}

export default About;
