import React from "react";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import CatalogMetals from "./pages/CatalogMetals";
import CardProduct from "./pages/CardProduct";
import Contacts from "./pages/Contacts";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Thanks from "./pages/Thanks";
import Error from "./pages/Error";
import Policy from "./pages/Policy";
import { Route, Switch } from "react-router-dom";
import ScrollToTop from "./include/ScrollToTop";
import client from "./include/client";

import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectFade]);

function App() {
  ScrollToTop();

  //Получение данных из contentful
  const [products, setProducts] = React.useState([]);
  const [isLoad, setIsLoad] = React.useState(false);

  React.useEffect(() => {
    client.getEntries()
      .then((response) => {
        setProducts(response.items)
        setIsLoad(true);
      })
      .catch(console.error)
  }, []);

  return (
    <div className="App">
      <Header items={products} isLoad={isLoad}></Header>

      <Switch>
        <Route path="/" render={Home} exact />

        <Route
          path="/catalog-windows"
          render={() => (
            <Catalog
              items={products}
              url={"/catalog-windows/"}
              catalogName={"Мансардные окна"}
              catalogSubtitle={"Лидер производства из Дании"}
              isLoad={isLoad}
            />
          )}
          exact
        />

        <Route
          path="/catalog-ceramic"
          render={() => (
            <Catalog
              items={products}
              url={"/catalog-ceramic/"}
              catalogName={"Керамическая черепица"}
              catalogSubtitle={"От Европейского производителя"}
              isLoad={isLoad}
            />
          )}
          exact
        />

        <Route
          path="/catalog-accessories"
          render={() => (
            <Catalog
              items={products}
              url={"/catalog-accessories/"}
              catalogName={"Кровельные материалы"}
              catalogSubtitle={"Любые решения для надежности кровли"}
              isLoad={isLoad}
            />
          )}
          exact
        />

        <Route
          path="/catalog-drainage-systems"
          render={() => (
            <Catalog
              items={products}
              url={"/catalog-drainage-systems/"}
              catalogName={"Водосточные системы"}
              catalogSubtitle={"Качественная сборка системы"}
              isLoad={isLoad}
            />
          )}
          exact
        />

        <Route
          path="/catalog-metal-tiles"
          render={() => (
            <Catalog
              items={products}
              url={"/catalog-metal-tiles/"}
              catalogName={"Металлическая черепица"}
              catalogSubtitle={"Европейское качество"}
              isLoad={isLoad}
            />
          )}
          exact
        />

        <Route path="/catalog-metals" render={() => <CatalogMetals />} exact />

        <Route
          path="/:url/:name"
          render={() => <CardProduct items={products} />}
          exact
        />

        <Route path="/policy" render={() => <Policy />} exact />
        <Route path="/gallery" render={Gallery} exact />
        <Route path="/about" render={About} exact />
        <Route path="/thanks" render={Thanks} exact />
        <Route path="/error" render={Error} exact />
        <Route path="/contacts" render={Contacts} exact />
      </Switch>

      <Footer></Footer>
    </div>
  );
}

export default App;
