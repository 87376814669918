import React from "react";
import "../include/contacts-form.css";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { useHistory } from 'react-router-dom';

function ContactsForm() {
  const formBlock = React.useRef();
  const formResultBlock = React.useRef();
  const formLoadingBlock = React.useRef();
  const history = useHistory();

  // функция отправки формы на почту
  async function formSend(e) {
    e.preventDefault();
    let form = formBlock.current;
    let formResult = formResultBlock.current;
    let formLoading = formLoadingBlock.current;

    // Действие во время отправки
    formLoading.classList.add("open");
    let formData = new FormData(form);
    form.reset();

    let response = await fetch("mail.php", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      let result = await response.json();
      form.reset();
      formLoading.classList.remove("open");
      formResult.classList.add("open");
      formResult.textContent = result.message;
      setTimeout(() => formResult.classList.remove("open"), 3000);
      setTimeout(() => history.push('/thanks'), 2000);
    } else {
      formResult.classList.add("open");
      formResult.textContent = "Ошибка, свяжитесь с нами по номеру телефона!";
      setTimeout(() => formResult.classList.remove("open"), 3000);
      formLoading.classList.remove("open");
      setTimeout(() => history.push('/error'), 2000);
    }
  }

  const [whiteColorMenu, setColorMenu] = React.useState(true);
  let location = useLocation().pathname;

  React.useEffect(() => {
    if (location === "/") {
      setColorMenu(true);
    } else {
      setColorMenu(false);
    }
  }, [location]);

  return (
    <form
      ref={formBlock}
      onSubmit={formSend}
      action="#"
      className={classnames("contacts__form", {
        "contacts__form--white": whiteColorMenu,
      })}
    >
      {/* Имя и email */}
      <div className="contacts__form-block">
        <input
          type="text"
          className="contacts__form-input"
          placeholder="Имя*"
          name="name"
          autoComplete="off"
          min="2"
          maxLength="50"
          required
        ></input>
        <input
          type="email"
          className="contacts__form-input"
          placeholder="E-mail*"
          name="email"
          autoComplete="off"
          min="2"
          maxLength="30"
          required
        ></input>
        <input
          type="text"
          className="contacts__form-input"
          placeholder="Номер телефона"
          name="phone"
          autoComplete="off"
          min="2"
          maxLength="30"
        ></input>
      </div>

      {/* Сообщение */}
      <textarea
        className="contacts__form-textarea"
        placeholder="Сообщение"
        name="message"
        autoComplete="off"
        maxLength="500"
      ></textarea>

      <div className="contacts__form-button-container">
        <button type="submit" className="contacts__form-button">
          Отправить
        </button>
        <span className="contacts__form-button-signature">
          Нажимая на кнопку отправить вы даете согласие на обработку ваших
          персональных данных
        </span>
      </div>

      <div ref={formResultBlock} className="contacts__form-result">
        <p className="contacts__form-result-text"></p>
      </div>
      <div ref={formLoadingBlock} className="contacts__form-loading">
        <p className="contacts__form-loading-text">Отправка...</p>
      </div>
    </form>
  );
}

export default ContactsForm;
