import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  let location = useLocation().pathname;

    return (
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [location])
    );
    }

export default ScrollToTop;
