import React from "react";
import "./Catalog.css";
import CasesList from "../../include/CasesList";
import MetaTags from "react-meta-tags";
import BreadCrumbs from "../../include/BreadCrumbs";

function Catalog({ items, url, catalogName, catalogSubtitle, isLoad }) {

  return (
    <div className="catalog-roof-windows">

      <MetaTags>
        <title>{catalogName}</title>
        <meta
          name="description"
          content={`${catalogName} - большой выбор в Калининграде, выгодные цены, только самая качественная продукция от известных производителей Европы`}
        />

        {/* Добавить еще */}
        <meta
          name="keywords"
          content="Мансардные окна в Калининграде, Недорогие мансардные окна, Большие мансардные окна, Окна в крышу, Окна на крыше, Окна Dakea, Окна в частный дом, Керамическая черепица, Кровельные маткриалы"
        />
      </MetaTags>
      
      <BreadCrumbs url={url} catalogName={catalogName}/>
      <div className="container">

        <div className="catalog-roof-windows__title-container">
          <h1 className="catalog-roof-windows__title">{catalogName}</h1>
          <p className="catalog-roof-windows__subtitle">
            {catalogSubtitle}
          </p>
        </div>
        <div className="catalog-roof-windows__wrapper">
          <CasesList
            catalogName={catalogName}
            items={items}
            url={url}
            isLoad={isLoad}
          ></CasesList>
        </div>
      </div>

    </div>
  );
}

export default Catalog;
