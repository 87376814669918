import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./gallery.css";
import MetaTags from "react-meta-tags";

import houseFirst from "../img/gallery/house-first.jpeg";
import houseSecond from "../img/gallery/house-second.jpeg";
import houseThird from "../img/gallery/house-third.jpeg";
import houseFour from "../img/gallery/house-four.jpeg";

function Gallery() {
  return (
    <div className="gallery">

      <h1 className="visually-hidden">Галерея работ</h1>

      <MetaTags>
        <title>Галерея работ</title>
        <meta
          name="description"
          content="Все для кровли в Калининграде, напрямую от заводов производителей, большой выбор, оптимальные цены. Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь."
        />
        <meta
          name="keywords"
          content="Галерея наших работ, Все для кровли в Калининграде, Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь, Консультирование по кровельным материалам, Материалы для крыши, Крыша на доме, Качественная крыша, Надежная крыша, Недорогие кровельные материалы"
        />
      </MetaTags>

      <Swiper
        // effect={"fade"}
        loop={true}
        speed={400}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: ".gallery__arrow-prev",
          nextEl: ".gallery__arrow-next",
        }}
        pagination={{
          el: ".card-product__pagination",
          clickable: true,
          renderBullet: (index, className) => {
            return `<div class="${className}">
                </div>`;
          },
        }}
      >
        <SwiperSlide>
          <img className="gallery__slide-img" src={houseSecond} alt="Выполненный объект"></img>
          <div className="gallery__slide-description">
            <h3 className="gallery__slide-title">Мансардное окно Dakea</h3>
            <p className="gallery__slide-text">Зеленоградск, Прибрежная 94</p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <img className="gallery__slide-img" src={houseFirst} alt="Выполненный объект"></img>
          <div className="gallery__slide-description">
            <h3 className="gallery__slide-title">Окна Dakea</h3>
            <p className="gallery__slide-text">Калининград, Озерная 25</p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <img className="gallery__slide-img" src={houseThird} alt="Выполненный объект"></img>
          <div className="gallery__slide-description">
            <h3 className="gallery__slide-title">Черепица Wienerberger</h3>
            <p className="gallery__slide-text">Калининград, Красная 105</p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <img className="gallery__slide-img" src={houseFour} alt="Выполненный объект"></img>
          <div className="gallery__slide-description">
            <h3 className="gallery__slide-title">Черепица Wienerberger</h3>
            <p className="gallery__slide-text">Калининград, Зеленая 19</p>
          </div>
        </SwiperSlide>

        <div className="container">
          <div className="main-page__arrow-container">
            <div className="container">
              <div className="gallery__arrow-wrapper">
                <div className="gallery__arrow gallery__arrow-prev"></div>
                <div className="gallery__arrow gallery__arrow-next"></div>
              </div>
            </div>
          </div>

          <div className="main-page__pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default Gallery;
