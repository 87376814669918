import React from "react";
import CasesItem from "./CasesItem";

function CasesList({ items, url, catalogName, isLoad }) {

  const renderItems = () => {
    const filtredItems = items.filter((item) =>
      item.fields.category.toLowerCase().includes(catalogName.toLowerCase())
    );

    // const sortItems = filtredItems.sort((a, b) => a.fields.filter > b.fields.filter ? 1 : -1)

    const sortItems = filtredItems.sort(function (a, b) {
      if (a.fields.filter === undefined || a.fields.filter === null || a.fields.filter < 0) {
        a.fields.filter = 0;
      } else if (b.fields.filter === undefined || b.fields.filter === null || b.fields.filter < 0) {
        b.fields.filter = 0;
      }
      return a.fields.filter < b.fields.filter ? 1 : -1;
    });

    return (!isLoad ? [...Array(8)] : filtredItems).map((caseItem, index) => (
      <CasesItem key={index} caseItem={caseItem} index={index} url={url} isLoad={isLoad}></CasesItem>
    ));
  };

  return (
    <>
      <ul className="catalog-roof-windows__list">
        {renderItems()}
      </ul>
    </>
  );
}

export default CasesList;
