import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import logoIcon from "../img/header/logo.svg"

function YandexMap({ zoomValue }) {

  function createBalloonLayout(ymaps) {
    const BalloonLayout = ymaps.templateLayoutFactory.createClass(
      '<div>new balloon</div>', {
    });
    return BalloonLayout
  }

  return (
    <YMaps>
      <Map className="yandex-map" defaultState={{ center: [54.730198, 20.565159], zoom: zoomValue }}>
        {/* <Placemark defaultGeometry={[54.730198, 20.565159]} /> */}
        <Placemark
          defaultGeometry={[54.730198, 20.565159]}
          options={{
            iconLayout: 'default#image',
            iconImageHref: logoIcon,
            iconImageSize: [60, 60],
            iconImageOffset: [-35, -75]
            // iconColor: 'red'
          }}
        />
      </Map>
    </YMaps>
  )
}

export default YandexMap