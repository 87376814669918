import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import MetaTags from "react-meta-tags";
import MainPage from "../include/MainPage";
import ContactsForm from "../include/ContactsForm";
import YandexMap from "../include/YandexMap";

import windowImg from "../img/catalog/window.jpg";
import metImg from "../img/catalog/met.jpg";
import tileImg from "../img/catalog/tile.jpg";
import kirpichImg from "../img/catalog/kirpich.jpg";
import drainageImg from "../img/catalog/drainage.jpg";
import metTilesImg from "../img/catalog/met-tiles.jpg";

function Home() {
  return (
    <div>

      <MetaTags>
        <title>Все для кровли в Калининграде</title>
        <meta name="description" content="Все для кровли в Калининграде, напрямую от заводов производителей, большой выбор, оптимальные цены. Мансардные окна, Керамическая черепица, Кровельные материалы, Кровельный алюминий и медь." />
        <meta name="keywords" content="Все для кровли в Калининграде, Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь, Консультирование по кровельным материалам, Материалы для крыши, Крыша на доме, Качественная крыша, Надежная крыша, Недорогие кровельные материалы, ФБХ кровля, ФБХ Калининград"/>
      </MetaTags>

      <h1 className="visually-hidden">ФБХ Калининград это мансардные окна, керамическая черепица, кровельные аксессуары, кровельный алюминий и медь в Калининграде</h1>

      <MainPage></MainPage>
      <div className="catalog">
        <Swiper
          loop={true}
          speed={1100}
          spaceBetween={40}
          slidesPerView={3}
          centeredSlides={true}
          breakpoints={{
            // Если >= 550 то
            550: {
              slidesPerView: 3,
            },
            // Если >= 0 то
            0: {
              slidesPerView: 1,
            },
          }}
          navigation={{
            prevEl: ".catalog__arrow-prev",
            nextEl: ".catalog__arrow-next",
          }}
          pagination={{
            el: ".catalog__pagination",
            clickable: true,
            renderBullet: (index, className) => {
              return `<div class="${className}"></div>`;
            },
          }}
        >
          <SwiperSlide className="catalog__item">
            <Link to="/catalog-windows">
              <img className="catalog__item-img" src={windowImg} alt="Мансардные окна"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">
                  Лидер производства из Дании
                </span>
                <h3 className="catalog__item-title">
                  Мансардные
                  <br /> окна
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="catalog__item">
            <Link to="/catalog-ceramic">
              <img className="catalog__item-img" src={tileImg} alt="Керамическая черепица"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">
                  От Европейского производителя
                </span>
                <h3 className="catalog__item-title">
                  Керамическая
                  <br /> черепица
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="catalog__item">
            <Link to="/catalog-accessories">
              <img className="catalog__item-img" src={kirpichImg} alt="Кровельные материалы"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">Большой выбор</span>
                <h3 className="catalog__item-title">
                  Кровельные
                  <br /> материалы
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="catalog__item">
            <Link to="/catalog-metals">
              <img className="catalog__item-img" src={metImg} alt="Алюминий и медь"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">
                  Возможен любой вид рисунка
                </span>
                <h3 className="catalog__item-title">
                  Алюминий
                  <br /> и медь
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="catalog__item">
            <Link to="/catalog-metal-tiles">
              <img className="catalog__item-img" src={metTilesImg} alt="Меиаллическая черепица"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">
                  Европейское качество
                </span>
                <h3 className="catalog__item-title">
                  Металлическая
                  <br /> черепица
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide className="catalog__item">
            <Link to="/catalog-drainage-systems">
              <img className="catalog__item-img" src={drainageImg} alt="Водосточные системы"></img>
              <div className="catalog__item-description">
                <span className="catalog__item-signature">
                  Качественная сборка системы
                </span>
                <h3 className="catalog__item-title">
                  Водосточные
                  <br /> системы
                </h3>
              </div>
            </Link>
          </SwiperSlide>

          <div>
            <div className="catalog__arrow-container">
              <div className="container">
                <div className="catalog__arrow-wrapper">
                  <div className="catalog__arrow catalog__arrow-prev"></div>
                  <div className="catalog__arrow catalog__arrow-next"></div>
                </div>
                <div className="catalog__pagination-container">
                  <div className="catalog__pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </div>
      
      <div className="consultation">
        <div className="container">
          <div className="consultation-container">
          <div className="consultation-wrapper">
            <h2 className="consultation__title">Нужна консультация?</h2>
            <p className="consultation__text">
              Специалисты компании помогут Вам выбрать подходящее решение для вашей кровли
            </p>

            <ContactsForm></ContactsForm>
          </div>
          <div className="consultation-map">
            <YandexMap zoomValue="16"></YandexMap>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
