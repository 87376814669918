import React from "react";
import ContactsForm from "../include/ContactsForm";
import "./contacts.css";
import MetaTags from "react-meta-tags";
import YandexMap from "../include/YandexMap";
import { Link } from "react-router-dom";

function Contacts() {
  return (
    <div className="contacts">

      <MetaTags>
        <title>Контакты</title>
        <meta
          name="description"
          content="Все для кровли в Калининграде, напрямую от заводов производителей, большой выбор, оптимальные цены. Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь."
        />
        <meta
          name="keywords"
          content="Контакты, Все для кровли в Калининграде, Мансардные окна, Керамическая черепица, Кровельные аксессуары, Кровельный алюминий и медь, Консультирование по кровельным материалам, Материалы для крыши, Крыша на доме, Качественная крыша, Надежная крыша, Недорогие кровельные материалы"
        />
      </MetaTags>

      <div className="container">
        <div className="bread-crumbs">
          <ul className="bread-crumbs__list">
            <li className="bread-crumbs__item">
              <Link className="bread-crumbs__link" to="/">
                Главная
              </Link>
            </li>
            <li className="bread-crumbs__item">
              <span className="bread-crumbs__link bread-crumbs__link--active">
                Контакты
              </span>
            </li>
          </ul>
        </div>

        <div className="contacts__title-container">
          <h1 className="contacts__title">Свяжитесь с нами</h1>
          <span className="contacts__subtitle">Контакты</span>
        </div>

        <div className="contacts__wrapper">
          <div className="contacts__information">
            <div className="contacts__information-item">
              <h4 className="contacts__information-title">Офис и склад компании</h4>
              <p className="contacts__information-text">
                г. 236038, Калининградская область, город Калининград, улица
                Гагарина, дом 108
              </p>
            </div>

            <div className="contacts__information-item">
              <h4 className="contacts__information-title">Время работы</h4>
              <p className="contacts__information-text">Пн-пт: 09:00 - 18:00</p>
              <p className="contacts__information-text">Сб: 10:00 - 15:00</p>
            </div>
            <div className="contacts__information-item">
              <h4 className="contacts__information-title">Телефон</h4>
              <div>
                <a className="contacts__information-link" href="tel:84012777444">
                  8 (4012) 777-444
                </a>
              </div>
              <div>
                <a className="contacts__information-link" href="tel:89632907000">
                  8 (963) 29-07-000
                </a>
              </div>
            </div>
            <div className="contacts__information-item">
              <h4 className="contacts__information-title">E-mail</h4>
              <a className="contacts__information-link" href="mansarda-shop@mail.ru">
                mansarda-shop@mail.ru
              </a>
            </div>
            <div className="contacts__information-item">
              <h4 className="contacts__information-title contacts__information-title--social">Мессенджеры</h4>
              {/* Ссылки Вконтакте и инстаграм */}
              {/* <a
                href="https://www.google.com/"
                className="contacts__top-social-link contacts__top-social-link--vk"
              >
                <svg
                  className="contacts__top-social-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20.7 11.95"
                >
                  <title>Вконтакте</title>
                  <g id="Слой_2" data-name="Слой 2">
                    <g id="Слой_1-2" data-name="Слой 1">
                      <path d="M6.72.69A4.16,4.16,0,0,1,8,.07a17,17,0,0,1,3,0c.88.08,1.11.43,1.11,1.32,0,1.11,0,2.23,0,3.34a2.59,2.59,0,0,0,.33.87c.26-.18.61-.3.77-.54.74-1.21,1.48-2.42,2.12-3.68A1.25,1.25,0,0,1,16.73.55c1,0,1.93,0,2.89,0s1.34.41.79,1.3c-.79,1.27-1.66,2.48-2.53,3.7-.76,1-.74,1.26.16,2.18s1.63,1.8,2.42,2.71c.43.5.25.91-.27,1.17a2.67,2.67,0,0,1-1,.28c-.66,0-1.33-.05-2,0a2.85,2.85,0,0,1-2.47-1.08c-.41-.44-.8-.91-1.23-1.34s-.94-.41-1.17.3a3.86,3.86,0,0,0-.16,1c-.07.84-.36,1.13-1.19,1.15A7.71,7.71,0,0,1,4.52,9,32.4,32.4,0,0,1,.11,1.5C-.15.94.06.59.67.57c1,0,2,0,3,0,.62,0,.83.46,1.08.92C5.36,2.66,6,3.85,6.73,5c.16.27.53.41.8.61a3.81,3.81,0,0,0,.43-1,17.22,17.22,0,0,0,0-1.9C8,1.9,7.85,1.16,6.77,1,6.76.91,6.74.8,6.72.69Z" />
                    </g>
                  </g>
                </svg>
              </a>
              <a
                href="https://www.google.com/"
                className="contacts__top-social-link contacts__top-social-link--instagram"
              >
                <svg
                  className="contacts__top-social-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18.11 18.09"
                >
                  <title>Инстаграм</title>
                  <g id="Слой_2" data-name="Слой 2">
                    <g id="Слой_1-2" data-name="Слой 1">
                      <path d="M9.11.05c1.45,0,2.9-.08,4.34,0A4.66,4.66,0,0,1,18,4.64c.13,2.88.12,5.79,0,8.68A4.7,4.7,0,0,1,13.2,18a78.48,78.48,0,0,1-8.26,0C1.86,17.81.14,15.91.05,12.8,0,10.26,0,7.71.05,5.18.14,2,2,.21,5.09,0c1.33-.08,2.68,0,4,0ZM9,16.4c1.3,0,2.61.05,3.92,0,2.16-.1,3.4-1.2,3.48-3.34.11-2.68.11-5.37,0-8a3.1,3.1,0,0,0-3.25-3.34c-2.75-.12-5.51-.12-8.26,0A3.1,3.1,0,0,0,1.69,5c-.09,2.64-.09,5.3,0,7.94C1.76,15.14,3,16.29,5.2,16.39,6.47,16.45,7.74,16.4,9,16.4Z" />
                      <path d="M9,13.64A4.63,4.63,0,0,1,4.41,9,4.66,4.66,0,1,1,9,13.64ZM12.11,9a3.05,3.05,0,1,0-3,3A3.06,3.06,0,0,0,12.11,9Z" />
                      <path d="M13.73,5.38c-.29-.41-.85-.84-.8-1.2a1.51,1.51,0,0,1,1-1c.27-.06.93.56,1,.93s-.44.84-.69,1.26Z" />
                    </g>
                  </g>
                </svg>
              </a> */}
              <a
                href="viber://chat?number=%2B79632907000"
                className="contacts__top-social-link contacts__top-social-link--viber"
              >
                <svg
                  className="contacts__top-social-icon"
                  width="16px"
                  height="16px"
                  id="Bold"
                  viewBox="0 0 24 24"
                >
                  <path d="m23.155 13.893c.716-6.027-.344-9.832-2.256-11.553l.001-.001c-3.086-2.939-13.508-3.374-17.2.132-1.658 1.715-2.242 4.232-2.306 7.348-.064 3.117-.14 8.956 5.301 10.54h.005l-.005 2.419s-.037.98.589 1.177c.716.232 1.04-.223 3.267-2.883 3.724.323 6.584-.417 6.909-.525.752-.252 5.007-.815 5.695-6.654zm-12.237 5.477s-2.357 2.939-3.09 3.702c-.24.248-.503.225-.499-.267 0-.323.018-4.016.018-4.016-4.613-1.322-4.341-6.294-4.291-8.895.05-2.602.526-4.733 1.93-6.168 3.239-3.037 12.376-2.358 14.704-.17 2.846 2.523 1.833 9.651 1.839 9.894-.585 4.874-4.033 5.183-4.667 5.394-.271.09-2.786.737-5.944.526z" />
                  <path d="m12.222 4.297c-.385 0-.385.6 0 .605 2.987.023 5.447 2.105 5.474 5.924 0 .403.59.398.585-.005h-.001c-.032-4.115-2.718-6.501-6.058-6.524z" />
                  <path d="m16.151 10.193c-.009.398.58.417.585.014.049-2.269-1.35-4.138-3.979-4.335-.385-.028-.425.577-.041.605 2.28.173 3.481 1.729 3.435 3.716z" />
                  <path d="m15.521 12.774c-.494-.286-.997-.108-1.205.173l-.435.563c-.221.286-.634.248-.634.248-3.014-.797-3.82-3.951-3.82-3.951s-.037-.427.239-.656l.544-.45c.272-.216.444-.736.167-1.247-.74-1.337-1.237-1.798-1.49-2.152-.266-.333-.666-.408-1.082-.183h-.009c-.865.506-1.812 1.453-1.509 2.428.517 1.028 1.467 4.305 4.495 6.781 1.423 1.171 3.675 2.371 4.631 2.648l.009.014c.942.314 1.858-.67 2.347-1.561v-.007c.217-.431.145-.839-.172-1.106-.562-.548-1.41-1.153-2.076-1.542z" />
                  <path d="m13.169 8.104c.961.056 1.427.558 1.477 1.589.018.403.603.375.585-.028-.064-1.346-.766-2.096-2.03-2.166-.385-.023-.421.582-.032.605z" />
                </svg>
              </a>
              <a
                href="https://wa.me/79632907000"
                className="contacts__top-social-link contacts__top-social-link--telegram"
              >
                <svg
                  className="contacts__top-social-icon"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 308 308"
                >
                  <path
                    id="XMLID_469_"
                    d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
                  />
                  <path
                    id="XMLID_470_"
                    d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
                  />
                </svg>
              </a>
            </div>
          </div>

          <YandexMap zoomValue="16"></YandexMap>
        </div>
      </div>
      <div className="container">
        <div className="contacts__title-container">
          <h2 className="contacts__title">Напишите нам</h2>
          <span className="contacts__subtitle">Оставьте заявку</span>
        </div>
        <ContactsForm></ContactsForm>
      </div>
    </div>
  );
}

export default Contacts;
